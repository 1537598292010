import React, { useEffect, useState, useRef } from 'react';
import { MuseClient } from 'muse-js';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Legend,
  Tooltip,
} from 'chart.js';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import ArduinoControl from '../arduino_util/arduinoControl.js';

// Register Chart.js components
ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale, Legend, Tooltip);

const RecordingPage = () => {

  // plotting state variables
  const [plotData, setPlotData] = useState({
    time_eeg: [],
    signal0: [],
    signal1: [],
    signal2: [],
    signal3: [],
    time_ppg: [],
    ppg1: [],
    ppg2: [],
    ppg3: [],
    time_acc:  [],
    x_acc: [],
    y_acc: [],
    z_acc: [],
    time_gyro:  [],
    x_gyro: [],
    y_gyro: [],
    z_gyro: [],
  });

  // other state variables
  const [isConnected, setIsConnected] = useState(false);
  const [museClient, setMuseClient] = useState(null);
  const [subjectName, setSubjectName] = useState(""); // State for the subject's name
  const [uploadIntervalId, setUploadIntervalId] = useState(null);
  const [connectionIntervalId, setConnectionIntervalId] = useState(null);
  const [plotIntervalId, setPlotIntervalId] = useState(null);
  const [arduinoControl, setArduinoControl] = useState(null);
  const isManualDisconnect = useRef(false);

  // data upload buffer variables
  let activeEEGData = { time: [], timeWeb: [], signal0: [], signal1: [], signal2: [], signal3: [] };
  let activePPGData = { time: [], timeWeb: [], ppg1: [], ppg2: [], ppg3: [] };
  let activeAccData = { time: [], x_acc: [], y_acc: [], z_acc: [] };
  let activeGyroData = { time: [], x_gyro: [], y_gyro: [], z_gyro: [] };
  let activeEvtData = { time: [], value: []};
  
  // arduino variables
  const arduinoControlInstance=new ArduinoControl();

  // muse client variables
  const mclient = new MuseClient();
  mclient.enablePpg = true;
  let bluetoothdevice=null;

  // subject name variables
  let subjectNamee=null;
  const setSubjectNameFunc = (namee)=>{
    setSubjectName(namee)
    subjectNamee=namee;
  }

  // timed task variables
  let uid=null; // upload task
  let cid=null; // connection check task
  let pid=null; // plotting task

  //reconection related
  let disconnected=false;
  let reconnectTry=0;
  let currentAttemptingSign=false;
  let logss = "";

  // -------------------------------------------------------------------- functions -----------------------------------------------------------------------------
  const client = new S3Client({
    region: process.env.REACT_APP_REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: process.env.REACT_APP_REGION },
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    }),
  });

  const bucketName = process.env.REACT_APP_BUCKET_NAME;

  const averageSubarrays = (arr, maxLength)=> {
    if(arr.length<=0){
      return [];
    }
    const valueToExclude = -100000000000; // The value to filter out
    // Get the last maxLength subarrays
    const lastSubarrays = arr.slice(-maxLength);
    // Map over each subarray and calculate its average
    const averages = lastSubarrays.map(subarray => {
      if (Array.isArray(subarray)) {
        const filtered = subarray.filter(num => num !== valueToExclude);
        const sum = filtered.reduce((acc, num) => acc + num, 0);
        return subarray.length > 0 ? sum / subarray.length : 0; // Avoid division by zero
      } else {
        // Handle non-array elements gracefully
        return null; // Or any fallback value
      }
    });
    return averages;
  }

  const limitLength = (arr, maxLength) => arr.slice(-maxLength);

  const initializeConnection = async () => {
    if (subjectName.includes("_")) {
      alert('subject name should not have _ ')
      return;
    }
    if (subjectName === null || subjectName === undefined || subjectName === "") {
      alert('subject name should not be empty, input anything')
      return;
    }

    try {
      await arduinoControlInstance.connect();
      console.log(arduinoControlInstance.port);
      setArduinoControl(arduinoControlInstance);

      console.log('Connecting...');
      logss=logss+"Connecting...\n";
      await mclient.connect();

      console.log('Starting...');
      logss=logss+"Starting...\n";
      await mclient.start();

      setMuseClient(mclient);
      isManualDisconnect.current = false; // Reset flag
      console.log(mclient)
      bluetoothdevice=mclient.gatt.device;
      setIsConnected(true);
      startReading(mclient);

      pid = setInterval(() => {  
       collectingPlottingDat();
      }, 100); // 10HZ plotting
      setPlotIntervalId(pid);

      // Start timed upload
      uid = setInterval(() => {  
        uploadWarpper();
      }, 5 * 60 * 1000); // Every 5 minutes
      setUploadIntervalId(uid); // Store interval ID

     // handle disconnection 1: check if device status is disconnected
      cid=setInterval(() => {
        if (!mclient.gatt) {
          console.log('Device is disconnected');
          if (!currentAttemptingSign){ // ensures only one handleDisconnection is operating at a monent
            console.log('Attempt reconnect');
            currentAttemptingSign=true;
            handleDisconnection();
          }
        }else{
          console.log('working')
        }
      }, 2000);
      setConnectionIntervalId(cid);


    } catch (error) {
      console.error("Connection failed:", error);
      alert("Unable to connect to the Muse device");
      return;
    }
  };
  
  const handleDisconnection = async()=>{
    if (!isManualDisconnect.current) {
      // Attempt to reconnect if the disconnection was accidental
      disconnected=true;
      try {
        await reconnect(bluetoothdevice);
        disconnected=false;
        currentAttemptingSign=false;
        console.log('disconnection handled:'+'disconnected sign:'+disconnected+' currentAttempting sign:'+currentAttemptingSign)
      } catch (error) {
        if(mclient.gatt){ // connected but service not started
          await bluetoothdevice.gatt.disconnect();
          mclient.gatt=null;
          console.log('service start unsuccessful, disconnected, trying reconnection');
        }
      
        console.error('Reconnection failed:', error);
        currentAttemptingSign=false;
      }
    } else {
      let currTime = new Date();
      console.log(
        'Reconnection skipped due to manual disconnection ' +
          currTime.getFullYear() +
          '/' +
          (currTime.getMonth() + 1) +
          '/' +
          currTime.getDate() +
          ',' +
          currTime.getHours() +
          ':' +
          currTime.getMinutes() +
          ':' +
          currTime.getSeconds()
      );
      
    }
  }

  const reconnect = async (bluetoothdeviceCurr) => {
    let currTime = new Date();
    console.log(
        'Reconnection attempt at ' +
          currTime.getFullYear() +
          '/' +
          (currTime.getMonth() + 1) +
          '/' +
          currTime.getDate() +
          ',' +
          currTime.getHours() +
          ':' +
          currTime.getMinutes() +
          ':' +
          currTime.getSeconds()
    );
    try {
      // bluetooth recionnection
      currTime = new Date();
      console.log('bluetooth reconnection' +
        currTime.getFullYear() +
        '/' +
        (currTime.getMonth() + 1) +
        '/' +
        currTime.getDate() +
        ',' +
        currTime.getHours() +
        ':' +
        currTime.getMinutes() +
        ':' +
        currTime.getSeconds());
      let newGatt = await bluetoothdeviceCurr.gatt.connect();

      // bluetooth reconnected, get service (connect muse client)
      currTime = new Date();
      console.log('Reconnected, client connecting' +
        currTime.getFullYear() +
        '/' +
        (currTime.getMonth() + 1) +
        '/' +
        currTime.getDate() +
        ',' +
        currTime.getHours() +
        ':' +
        currTime.getMinutes() +
        ':' +
        currTime.getSeconds());
      await connectWithTimeout(mclient,newGatt);
      
      //service acquired, send starting parameters to start record
      currTime = new Date();
      console.log('Reconnected, starting' +
        currTime.getFullYear() +
        '/' +
        (currTime.getMonth() + 1) +
        '/' +
        currTime.getDate() +
        ',' +
        currTime.getHours() +
        ':' +
        currTime.getMinutes() +
        ':' +
        currTime.getSeconds());
      await mclient.start();

      //start reading
      currTime = new Date();
      console.log('Reconnected, start reading' +
        currTime.getFullYear() +
        '/' +
        (currTime.getMonth() + 1) +
        '/' +
        currTime.getDate() +
        ',' +
        currTime.getHours() +
        ':' +
        currTime.getMinutes() +
        ':' +
        currTime.getSeconds());
      startReading(mclient);
      
      // finale
      currTime = new Date();
      console.log('Reconnection successful' +
        currTime.getFullYear() +
        '/' +
        (currTime.getMonth() + 1) +
        '/' +
        currTime.getDate() +
        ',' +
        currTime.getHours() +
        ':' +
        currTime.getMinutes() +
        ':' +
        currTime.getSeconds());

      reconnectTry=0;

    } catch(error) {
      if (reconnectTry>1200){
        currTime = new Date();
        alert(
          'Failed to reconnect after maximum attempt.' +
            currTime.getFullYear() +
            '/' +
            (currTime.getMonth() + 1) +
            '/' +
            currTime.getDate() +
            ',' +
            currTime.getHours() +
            ':' +
            currTime.getMinutes() +
            ':' +
            currTime.getSeconds()
        );
        console.error(error);

        mclient.disconnect();
        setMuseClient(null);
        console.log('Connection stopped.');

        clearInterval(uid); // Clear the interval
        setUploadIntervalId(null); // Reset interval ID
        console.log('Upload stopped.');
      
        clearInterval(cid); // Clear the interval
        setConnectionIntervalId(null); // Reset interval ID
        console.log('Reconnection check stopped.');
      
        throw error;
      }

      currTime = new Date();
      reconnectTry=reconnectTry+1;
      console.log('a reconnection try have been made: '+(1200-reconnectTry)+' remains'+
        currTime.getFullYear() +
        '/' +
        (currTime.getMonth() + 1) +
        '/' +
        currTime.getDate() +
        ',' +
        currTime.getHours() +
        ':' +
        currTime.getMinutes() +
        ':' +
        currTime.getSeconds());
      throw error;
    }
  }

  const connectWithTimeout = async (mclient,newGatt, timeout = 10000) =>{
    return Promise.race([
        mclient.connect(newGatt),
        new Promise((_, reject) =>
            setTimeout(() => reject(new Error('Connection timed out')), timeout)
        ),
    ]);
  }

  const startReading = (client) => {
    // arduinoControlInstance.addListener(listener);

    client.ppgReadings.subscribe((ppgReading) => {
      let timestampWeb = new Date();

      const { timestamp, ppgChannel, samples } = ppgReading;

      activePPGData.time.push(timestamp);
      activePPGData.timeWeb.push(timestampWeb.getTime());
      if(ppgChannel === 0){
        activePPGData.ppg1.push(samples);
        activePPGData.ppg2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activePPGData.ppg3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        }
        if(ppgChannel === 1){
          activePPGData.ppg1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
          activePPGData.ppg2.push(samples);
          activePPGData.ppg3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        }
        if(ppgChannel === 2){
          activePPGData.ppg1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
          activePPGData.ppg2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
          activePPGData.ppg3.push(samples);
        }

    });

    client.eegReadings.subscribe((reading) => {

      let timestampWeb = new Date();
      
      const { timestamp, electrode, samples } = reading;

      activeEEGData.time.push(timestamp);
      activeEEGData.timeWeb.push(timestampWeb.getTime());
      if(electrode === 0){
        activeEEGData.signal0.push(samples);
        activeEEGData.signal1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
      }
      if(electrode === 1){
        activeEEGData.signal0.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal1.push(samples);
        activeEEGData.signal2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
      }
      if(electrode === 2){
        activeEEGData.signal0.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal2.push(samples);
        activeEEGData.signal3.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
      }
      if(electrode === 3){
        activeEEGData.signal0.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal1.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal2.push([-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000,-100000000000]);
        activeEEGData.signal3.push(samples);
      }
      
    });

    client.accelerometerData.subscribe((reading) => {
      //console.log(reading)
      const { samples } = reading;
      let timestampWeb = new Date();

      activeAccData.time.push(timestampWeb.getTime());
      activeAccData.x_acc.push([samples['0']['x'], samples['1']['x'], samples['2']['x']]);
      activeAccData.y_acc.push([samples['0']['y'], samples['1']['y'], samples['2']['y']]);
      activeAccData.z_acc.push([samples['0']['z'], samples['1']['z'], samples['2']['z']]);

    });

    client.gyroscopeData.subscribe((reading) => {
      //console.log(reading)
      const { samples } = reading;
      let timestampWeb = new Date();

      activeGyroData.time.push(timestampWeb.getTime());
      activeGyroData.x_gyro.push([samples['0']['x'], samples['1']['x'], samples['2']['x']]);
      activeGyroData.y_gyro.push([samples['0']['y'], samples['1']['y'], samples['2']['y']]);
      activeGyroData.z_gyro.push([samples['0']['z'], samples['1']['z'], samples['2']['z']]);

    });

    console.log("Started reading EEG, PPG, acc, gryo data.");
  };

  const collectingPlottingDat = () => {
    setPlotData(() => ({
      time_eeg: limitLength(activeEEGData.timeWeb, 1000),
      signal0: averageSubarrays (activeEEGData.signal0, 1000),
      signal1: averageSubarrays (activeEEGData.signal1, 1000),
      signal2: averageSubarrays (activeEEGData.signal2, 1000),
      signal3: averageSubarrays (activeEEGData.signal3, 1000),
      time_ppg: limitLength(activeEEGData.timeWeb, 1000),
      ppg1: averageSubarrays (activePPGData.ppg1, 1000),
      ppg2: averageSubarrays (activePPGData.ppg2, 1000),
      ppg3: averageSubarrays (activePPGData.ppg3, 1000),
      time_acc:  limitLength (activeAccData.time, 1000),
      x_acc: averageSubarrays (activeAccData.x_acc, 1000),
      y_acc: averageSubarrays (activeAccData.y_acc, 1000),
      z_acc: averageSubarrays (activeAccData.z_acc, 1000),
      time_gyro:  limitLength (activeGyroData.time, 1000),
      x_gyro: averageSubarrays (activeGyroData.x_gyro, 1000),
      y_gyro: averageSubarrays (activeGyroData.y_gyro, 1000),
      z_gyro: averageSubarrays (activeGyroData.z_gyro, 1000),
    }));
  }

  const handleArduinoMessage = (message) =>{
      console.log(arduinoControl.port);
      let timestampWeb = new Date();
      activeEvtData.time.push(timestampWeb.getTime());
      activeEvtData.value.push(message?1:0);
      arduinoControl.sendMessage(message)
  }

  const stopConnection = () => {
    isManualDisconnect.current = true; // Mark as intentional disconnect
    setIsConnected(false); // Update state to reflect UI change
  
    if (museClient) {
      museClient.disconnect();
      setMuseClient(null);
      console.log('Connection stopped.');
    }
  
    if (uploadIntervalId) {
      clearInterval(uploadIntervalId); // Clear the interval
      setUploadIntervalId(null); // Reset interval ID
      console.log('Upload stopped.');
    }
  
    if (connectionIntervalId) {
      clearInterval(connectionIntervalId); // Clear the interval
      setConnectionIntervalId(null); // Reset interval ID
      console.log('Reconnection check stopped.');
    }

    if (plotIntervalId) {
      clearInterval(plotIntervalId); // Clear the interval
      setPlotIntervalId(null); // Reset interval ID
      console.log('Plotting stopped.');
    }

    let currTime = new Date();
    console.log(
      'Connection stopped at ' +
        currTime.getFullYear() +
        '/' +
        (currTime.getMonth() + 1) +
        '/' +
        currTime.getDate() +
        ',' +
        currTime.getHours() +
        ':' +
        currTime.getMinutes() +
        ':' +
        currTime.getSeconds()
    );
  };

  const uploadWarpper = ()=>{
    console.log("disconnected?"+disconnected)
    if (!disconnected){
      
      // Perform the timed upload logic here
      const saveTime = new Date();
      
      activePPGData.time=limitLength(activePPGData.time,2000*5);
      activePPGData.timeWeb=limitLength(activePPGData.timeWeb,2000*5);
      activePPGData.ppg1=limitLength(activePPGData.ppg1,2000*5);
      activePPGData.ppg2=limitLength(activePPGData.ppg2,2000*5);
      activePPGData.ppg3=limitLength(activePPGData.ppg3,2000*5);

      activeEEGData.time=limitLength(activeEEGData.time,5400*5);
      activeEEGData.timeWeb=limitLength(activeEEGData.timeWeb,5400*5);
      activeEEGData.signal0=limitLength(activeEEGData.signal0,5400*5);
      activeEEGData.signal1=limitLength(activeEEGData.signal1,5400*5);
      activeEEGData.signal2=limitLength(activeEEGData.signal2,5400*5);
      activeEEGData.signal3=limitLength(activeEEGData.signal3,5400*5);

      activeAccData.time=limitLength(activeAccData.time,1200*5);
      activeAccData.x_acc=limitLength(activeAccData.x_acc,1200*5);
      activeAccData.y_acc=limitLength(activeAccData.y_acc,1200*5);
      activeAccData.z_acc=limitLength(activeAccData.z_acc,1200*5);

      activeGyroData.time=limitLength(activeGyroData.time,1200*5);
      activeGyroData.x_gyro=limitLength(activeGyroData.x_gyro,1200*5);
      activeGyroData.y_gyro=limitLength(activeGyroData.y_gyro,1200*5);
      activeGyroData.z_gyro=limitLength(activeGyroData.z_gyro,1200*5);

      const csvData = convertToCSV(activeEEGData);
      const csvDataPPG = convertToCSVPPG(activePPGData);
      const csvDataBehav = convertToCSVBehav(activeAccData, activeGyroData);
      const csvDataArduino = convertToCSVArduino(activeEvtData);

      let fileName = `${subjectName}_eeg_data_${saveTime.getMonth() + 1}_${saveTime.getDate()}_${saveTime.getFullYear()}_${saveTime.getHours()}_${saveTime.getMinutes()}_${saveTime.getSeconds()}.csv`;
      uploadToS3(csvData, fileName);

      fileName = `${subjectName}_ppg_data_${saveTime.getMonth() + 1}_${saveTime.getDate()}_${saveTime.getFullYear()}_${saveTime.getHours()}_${saveTime.getMinutes()}_${saveTime.getSeconds()}.csv`;
      uploadToS3(csvDataPPG, fileName);

      fileName = `${subjectName}_behav_data_${saveTime.getMonth() + 1}_${saveTime.getDate()}_${saveTime.getFullYear()}_${saveTime.getHours()}_${saveTime.getMinutes()}_${saveTime.getSeconds()}.csv`;
      uploadToS3(csvDataBehav, fileName);

      fileName = `${subjectName}_arduino_data_${saveTime.getMonth() + 1}_${saveTime.getDate()}_${saveTime.getFullYear()}_${saveTime.getHours()}_${saveTime.getMinutes()}_${saveTime.getSeconds()}.csv`;
      uploadToS3(csvDataArduino, fileName);
    }
  }
  

  const convertToCSV = (eegData) => {
    const headers = ['Time', 'TimeWeb', 'Signal0', 'Signal1', 'Signal2', 'Signal3'];
    const maxLength = Math.max(eegData.time.length);

    const rows = Array.from({ length: maxLength }, (_, index) => [
      eegData.time[index] || '',
      eegData.timeWeb[index] || '',
      eegData.signal0[index] || '',
      eegData.signal1[index] || '',
      eegData.signal2[index] || '',
      eegData.signal3[index] || '',
    ]);

    return [headers, ...rows].map((e) => e.join(",")).join("\n");
  };

  const convertToCSVPPG = (ppgData) => {
    const headers = ['Time', 'TimeWeb', 'PPG1', 'PPG2', 'PPG3'];
    const maxLength = Math.max(ppgData.time.length);

    const rows = Array.from({ length: maxLength }, (_, index) => [
      ppgData.time[index] || '',
      ppgData.timeWeb[index] || '',
      ppgData.ppg1[index] || '',
      ppgData.ppg2[index] || '',
      ppgData.ppg3[index] || '',
    ]);

    return [headers, ...rows].map((e) => e.join(",")).join("\n");
  };

  const convertToCSVBehav = (accData, gyroData) => {
    const headers = ['Time','x_Acc','y_Acc','z_Acc','x_gyro','y_gyro','z_gyro'];
    const maxLength = Math.max(accData.time.length, gyroData.time.length);

    const rows = Array.from({ length: maxLength }, (_, index) => [
      accData.time[index] || gyroData.time[index] || '',
      accData.x_acc[index] || '',
      accData.y_acc[index] || '',
      accData.z_acc[index] || '',
      gyroData.x_gyro[index] || '',
      gyroData.y_gyro[index] || '',
      gyroData.z_gyro[index] || '',
    ]);

    return [headers, ...rows].map((e) => e.join(",")).join("\n");
  };

  const convertToCSVArduino = (arduinoData) => {
    const headers = ['Time','value'];
    const maxLength = arduinoData.time.length;

    const rows = Array.from({ length: maxLength }, (_, index) => [
      arduinoData.time[index] || '',
      arduinoData.value[index] || '',
    ]);

    return [headers, ...rows].map((e) => e.join(",")).join("\n");
  };

  const uploadToS3 = async (csvContent, fileName) => {

    try {
      const params = {
        Bucket: bucketName,
        Key: fileName,
        Body: csvContent,
        ContentType: "text/csv",
      };
      await client.send(new PutObjectCommand(params));
      console.log(`Uploaded data to S3: ${fileName}`);
    } catch (err) {
      console.error("Error uploading to S3:", err);
    }
  };

  return (
    <div className="main-page">
      <h1>EEG Recording Page</h1>
      
      {/* Subject name input */}
      <div style={{ margin: "20px 0" }}>
        <label>
          Subject Name:
          <input
            type="text"
            value={subjectName}
            onChange={(e) => {
              setSubjectNameFunc(e.target.value); // Updates the state
              console.log("Input Changed:", subjectNamee);
              console.log("Input Changed:", subjectName);
            }}
            placeholder="Enter subject name"
            style={{ marginLeft: "10px", padding: "5px" }}
          />
        </label>
      </div>

      {/* connection button */}
      {!isConnected ? (
        <button onClick={initializeConnection}>Connect EEG Device</button>
      ) : (
        <button onClick={stopConnection}>Stop Connection</button>
      )}
      {isConnected && <p>Connected to Muse device and reading EEG data...</p>}
  
      {/* Plot EEG data */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'right', margin: '20px 0' }}>
        <div></div>
        <Line
          data={{
            labels: plotData.time_eeg, // X-axis data
            datasets: [
              {
                label: `EEG Signal`,
                data: plotData.signal3, // eeg data, channel3
                borderColor: 'blue', // Line color
                borderWidth: 2,
                tension: 0.1, // Smooth line
              },
            ],
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: false,
                text: `EEG Signal`,
              },
              legend: {
                display: false, // Optional: hide legend for single dataset
              },
            },
            scales: {
              x: {
                display: false, // Disable Y-axis grid
              },
              y: {
                display: false, // Disable Y-axis grid
                min: -1000, // Minimum value for the Y-axis
                max: 1000,  // Maximum value for the Y-axis
              },
            },
          }}
          height={400}
          width={800}
        />

    </div>
      {/* Arduino buttons*/}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap' }}>
        <button onClick={() => handleArduinoMessage(false)}>send 0 to stimulation</button>
        <button onClick={() => handleArduinoMessage(true)}>send 1 to stimulation</button>
      </div> 
    </div>
  );
};

export default RecordingPage;
