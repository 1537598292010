// this page is only for displaying recorded EEG data and download the data, not intended for upload data
// for upload, goes to file exchange page
import React, { useEffect, useState, useCallback } from "react";
import { ListObjectsCommand, PutObjectCommand, GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";
import JSZip from "jszip";
import { saveAs } from "file-saver";

function DataPage() {
  const [objects, setObjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [selectedDownFile, setSelectedDownFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(""); // State for password error
  const [downloadProgress, setDownloadProgress] = useState(0);

  const client = new S3Client({
    region: process.env.REACT_APP_REGION,
    credentials: fromCognitoIdentityPool({
      clientConfig: { region: process.env.REACT_APP_REGION },
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    }),
  });

  const bucketName = process.env.REACT_APP_BUCKET_NAME;
  const correctPassword = process.env.REACT_APP_DOWNLOAD_PASSWORD;

  const fetchObjects = useCallback(async () => {
    setLoading(true);
    try {
      const command = new ListObjectsCommand({ Bucket: bucketName });
      const { Contents } = await client.send(command);
      console.log(Contents)
      const fileMap = {};

      Contents?.forEach((item) => {
        const key = item.Key;
        const lastModified = item.LastModified;

        const match = key.match(/^([^_]+)_([^_]+)_data_(\d{1,2}_\d{1,2}_\d{4}_\d{1,2}_\d{1,2}_\d{1,2})\.csv$/);

        if (match) {
          const subjectName = match[1];
          const endTime = match[3];

          if (!fileMap[subjectName]) {
            fileMap[subjectName] = { files: [], startTime: endTime, endTime: endTime };
          }

          fileMap[subjectName].files.push(key);

          if (lastModified) {
            const time = new Date(lastModified).toISOString().split("T")[0];
            if (time < fileMap[subjectName].startTime) {
              fileMap[subjectName].startTime = time;
            }
            if (time > fileMap[subjectName].endTime) {
              fileMap[subjectName].endTime = time;
            }
          } else {
            if (endTime > fileMap[subjectName].endTime) {
              fileMap[subjectName].endTime = endTime;
            }
          }
        }
      });

      const subjectList = Object.entries(fileMap).map(([name, { files, startTime, endTime }]) => ({
        name,
        files,
        startTime,
        endTime,
      }));

      console.log(subjectList)
      setSubjects(subjectList);
      setError("");
    } catch (err) {
      console.error("Error fetching data from S3:", err);
      setError("Failed to load data.");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchObjects();
  }, []);

  const handleFileClick = (subject) => {
    setSelectedDownFile(subject);
    setShowModal(true);
  };

  const handleDownloadFile = async () => {
    if (password !== correctPassword) {
      setPasswordError("Incorrect password.");
      return;
    }
    setPasswordError("");
    setDownloadProgress(0);

    const zip = new JSZip();
    console.log(selectedDownFile.files)
    let downloadedBytes = 0;
    let totalBytes=0;
    let count=0;
    let Bodies=[];
    let fileKeys=[]
    for (const fileKey of selectedDownFile.files) {
      try {
        const command = new GetObjectCommand({ Bucket: bucketName, Key: fileKey });
        const { Body, ContentLength } = await client.send(command);
        Bodies.push(Body);
        fileKeys.push(fileKey);
        totalBytes=totalBytes+ContentLength;
        count=count+1;
        setDownloadProgress((count / selectedDownFile.files.length) * 30);
      } catch (err) {
        console.error(`Error downloading file: ${fileKey}`, err);
      }
    }

    let reader=null;
    let stream=null;
    for (let i = 1; i<Bodies.length; i++) {
      try {
        reader = Bodies[i].getReader();
        stream = new ReadableStream({
          async start(controller) {
            while (true) {
              const { done, value } = await reader.read();
              if (done) break;
              controller.enqueue(value);
              downloadedBytes += value.length;
              setDownloadProgress((downloadedBytes / totalBytes) * 70+30);
            }
            controller.close();
            reader.releaseLock();
          },
        });

        const blob = await new Response(stream).blob();
        zip.file(fileKeys[i].split("/").pop(), blob);
        console.log(i)
      } catch (err) {
        console.error(`Error downloading file: ${fileKeys[i]}`, err);
      }
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${selectedDownFile.name}.zip`);
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setPassword(""); // Clear password field when closing modal
    setPasswordError("");
    setDownloadProgress(0);
  };

  return (
    <div>
      <h1>openmind's eeg data</h1>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <ul>
          {subjects.map((subject, index) => (
            <li key={index}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handleFileClick(subject);
                }}
              >
                {subject.name+': '+subject.startTime+'_'+subject.endTime} 
              </a>  
            </li>
          ))}
        </ul>
      )}

      {showModal && (
        <div style={{ position: 'fixed', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', zIndex: 1000, border: '1px solid black', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
          <button onClick={handleCloseModal} style={{ position: 'absolute', top: '10px', right: '10px', border: 'none', background: 'none', fontSize: '20px', cursor: 'pointer' }}>×</button>
          <h2>{"Download File"}</h2>
          {
            <>
              <input
                type="password"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                style={{ marginBottom: '10px', width: '100%' }}
              />
              {passwordError && <p style={{ color: 'red' }}>{passwordError}</p>}
              <button onClick={handleDownloadFile}>Start Download</button>
              <div style={{ marginTop: '10px', width: '100%', backgroundColor: '#f3f3f3', borderRadius: '5px' }}>
                <div style={{ width: `${downloadProgress}%`, height: '10px', backgroundColor: '#4caf50', borderRadius: '5px' }}></div>
              </div>
              <p>{Math.round(downloadProgress)}% downloaded</p>
            </>
          }
        </div>
      )}
      {showModal && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999 }} onClick={handleCloseModal}></div>
      )}
    </div>
  );
}

export default DataPage;